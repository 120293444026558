import { MdOutlineClose, MdOutlineContentCopy } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsThreeDots } from "react-icons/bs";
import { LuFileEdit } from "react-icons/lu";
import { HiOutlineLink } from "react-icons/hi";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";

type Props = {
	showCancelButton?: boolean;
	cancelButtonFunction?: () => void;
	showDuplicateButton?: boolean;
	duplicateButtonFunction?: () => void;
	showObservationsButton?: boolean;
	observationsButtonFunction?: () => void;
	fetchBeforeAction?: () => Promise<void>;
	occurrenceNumber?: string;
	attendanceId: string;
	showLinkButton?: boolean;
	linkButtonFunction?: () => void;
};

export function ActionsButton({
	showCancelButton = false,
	cancelButtonFunction,
	showDuplicateButton = false,
	duplicateButtonFunction,
	showObservationsButton = false,
	observationsButtonFunction,
	occurrenceNumber,
	attendanceId,
	fetchBeforeAction,
	showLinkButton = false,
	linkButtonFunction,
}: Props) {
	const { Toast, Swal } = useSwal();
	const { api } = useApi();

	async function handleDuplicateAttendance() {
		try {
			await api.post("/attendance/duplicate", {
				attendance_id: attendanceId,
			});

			Toast.fire({
				icon: "success",
				title: "Atendimento duplicado com sucesso!",
			});

			fetchBeforeAction && (await fetchBeforeAction());
		} catch (error: any) {
			console.log(error);
		}
	}

	async function confirmDuplicate() {
		const { isConfirmed } = await Swal.fire({
			text: `Tem certeza que quer duplicar a ocorrência N° ${occurrenceNumber}?`,
			icon: "warning",
			showCancelButton: true,
		});

		if (isConfirmed) {
			duplicateButtonFunction || handleDuplicateAttendance();
		}
	}

	return (
		<OverlayTrigger overlay={<Tooltip>Mais opções</Tooltip>}>
			<Dropdown className="position-static">
				<Dropdown.Toggle
					className="btn btn-icon btn-sm bg-secondary dropdown-after-none"
					id="dropdown-basic"
					as="button"
				>
					<BsThreeDots className="text-gray-700" />
				</Dropdown.Toggle>

				<Dropdown.Menu>
					{showCancelButton && (
						<Dropdown.Item
							as="button"
							className="px-4 py-1 d-flex align-items-center btn bg-light-hover color-orange-hover gap-2"
							onClick={cancelButtonFunction}
						>
							<MdOutlineClose /> Cancelar
						</Dropdown.Item>
					)}

					{showDuplicateButton && (
						<Dropdown.Item
							as="button"
							className="px-4 py-1 d-flex align-items-center btn bg-light-hover color-orange-hover gap-2"
							onClick={confirmDuplicate}
						>
							<MdOutlineContentCopy /> Duplicar
						</Dropdown.Item>
					)}

					{showObservationsButton && (
						<Dropdown.Item
							as="button"
							className="px-4 py-1 d-flex align-items-center btn bg-light-hover color-orange-hover gap-2"
							onClick={observationsButtonFunction}
						>
							<LuFileEdit /> Observação
						</Dropdown.Item>
					)}

					{showLinkButton && (
						<Dropdown.Item
							as="button"
							className="px-4 py-1 d-flex align-items-center btn bg-light-hover color-orange-hover gap-2"
							onClick={linkButtonFunction}
						>
							<HiOutlineLink /> Vincular
						</Dropdown.Item>
					)}
				</Dropdown.Menu>
			</Dropdown>
		</OverlayTrigger>
	);
}
