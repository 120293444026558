import { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import { toAbsoluteUrl } from "@/../_metronic/helpers";
import { usePusher } from "@/hooks/usePusher";
import { useApi } from "@/hooks/useApi";
import { TimeUnitMessages } from "@/enums/TimeUnit";
import { AttendanceStatus } from "@/enums/AttendanceStatus";

import { Separator } from "@/components/Separator";
import { Timeline } from "@/components/Timeline";

type MonitoringItem = {
	label: string;
	time: string;
	closed_jusitification?: string;
};

type FormatAddressParams = {
	street: string;
	houseNumber: string;
	city: string;
	neighborhood: string;
	federalUnit: string;
};

export function Monitoring() {
	const [attendanceMonitoring, setAttendanceMonitoring] = useState<AttendanceMonitoring>(
		{} as AttendanceMonitoring
	);
	const [isLinkExpired, setIsLinkExpired] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const { api } = useApi();
	const { attendanceId } = useParams();

	const { attendance } = attendanceMonitoring || {};
	const { attendable } = attendance || {};
	const { patient } = attendance || {};

	const attendanceStatusId = attendance?.attendance_status_id;

	const LoadingSpinner = () => (
		<div className="d-flex flex-center h-100">
			<Spinner animation="border" variant="samu" className="loading-spinner" />
		</div>
	);

	const timeUnitMessage =
		TimeUnitMessages[patient?.time_unit_id as keyof typeof TimeUnitMessages];

	const occurrenceAddress: FormatAddressParams = {
		street: attendable?.street,
		houseNumber: attendable?.house_number,
		city: attendance?.ticket?.city?.name,
		neighborhood: attendable?.neighborhood,
		federalUnit: attendance?.ticket?.city?.federal_unit?.uf,
	};

	function getMonitoringItems() {
		const relevantFields = [
			"attendance_requested_at",
			"vehicle_dispatched_at",
			"in_attendance_at",
			"attendance_completed_at",
		];

		const attendanceMonitoringEntries = Object.entries(attendanceMonitoring).filter(
			([key, value]) => {
				const isRelevant = relevantFields.includes(key);

				return isRelevant && value;
			}
		);

		const dictionary = {
			attendance_requested_at: {
				label: "Solicitação de atendimento.",
				time: dayjs(attendanceMonitoring.attendance_requested_at).format("HH:mm"),
			},
			vehicle_dispatched_at: {
				label: "Viatura enviada para o local.",
				time: dayjs(attendanceMonitoring.vehicle_dispatched_at).format("HH:mm"),
			},
			in_attendance_at: {
				label: "Em atendimento por SAMU Ceará.",
				time: dayjs(attendanceMonitoring.in_attendance_at).format("HH:mm"),
			},
			attendance_completed_at: {
				label: attendanceMonitoring?.canceled
					? "Sua ocorrência foi encerrada."
					: "Atendimento concluído.",
				time: dayjs(attendanceMonitoring.attendance_completed_at).format("HH:mm"),
				closed_jusitification: attendance?.scene_recording?.closed_justification,
			},
		};

		type KeyOfDictionary = keyof typeof dictionary;

		const formattedItems = attendanceMonitoringEntries.map(([key]) => {
			return dictionary[key as KeyOfDictionary];
		});

		return formattedItems;
	}

	const monitoringItems: MonitoringItem[] = getMonitoringItems();

	function formatAddress({
		street,
		houseNumber,
		neighborhood,
		city,
		federalUnit,
	}: FormatAddressParams) {
		const parts = [street, houseNumber, neighborhood].filter(Boolean);

		const address = parts.join(", ");

		const federalUnitLabel = federalUnit ? `/${federalUnit}` : "";

		return `${address} - ${city || ""}${federalUnitLabel}`;
	}

	async function fetchAttendance() {
		try {
			const { data } = await api.get(`attendance-monitoring/${attendanceId}`);

			setAttendanceMonitoring(data);
		} catch (error) {
			console.error(error);

			setIsLinkExpired(true);
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		fetchAttendance();
	}, []);

	const pusher = usePusher();

	useEffect(() => {
		const channelName = `attendance.change.${attendanceId}`;
		const eventName = `ChangeAttendance`;

		const channel = pusher.subscribe(channelName);
		channel.bind(eventName, async () => {
			await fetchAttendance();
		});
	}, []);

	if (isLinkExpired) {
		return (
			<div className="d-flex flex-center h-75">
				<div className="d-flex flex-column gap-10">
					<img
						src={toAbsoluteUrl("/media/images/monitoring/expired-link.svg")}
						alt="Imagem link expirado"
					/>

					<div className="text-center">
						<h1>Este link não está mais disponível</h1>
					</div>
				</div>
			</div>
		);
	}

	return isLoading ? (
		<LoadingSpinner />
	) : (
		<div>
			<h3>Dados da ocorrênia</h3>

			<Row className="fs-5">
				<Col>
					<span className="fw-bold">Protocolo: </span>
					{attendance?.number || "-"}
				</Col>

				<Col>
					<span className="fw-bold">Solicitado por: </span>
					{attendance?.ticket?.requester?.name || "-"}
				</Col>

				<Col sm={12}>
					<span className="fw-bold">Paciente: </span>
					{patient?.name || "-"}
				</Col>

				<Col sm={12}>
					<span className="fw-bold">Idade: </span>
					{patient?.age} {timeUnitMessage}
				</Col>

				<Col sm={12}>
					<div className="d-flex flex-column">
						<span className="fw-bold">Endereço da ocorrência: </span>
						{formatAddress(occurrenceAddress) || "-"}
					</div>
				</Col>
			</Row>

			<Separator />

			<h3 className="mb-3">Acompanhe a ocorrência</h3>

			<Timeline timeLineContainerClassNames="d-flex flex-column gap-8 bg-light-red">
				{monitoringItems?.map((item, index) => (
					<div key={`timeline-${index}`}>
						<Timeline.Item>
							<Timeline.VerticalLine />

							<Timeline.Content>
								<div className="d-flex flex-column">
									<span className="fw-bold">
										{item?.time} - {item?.label}
									</span>

									{item?.closed_jusitification}
								</div>
							</Timeline.Content>
						</Timeline.Item>
					</div>
				))}
			</Timeline>

			{(attendanceStatusId === AttendanceStatus.Conduct ||
				attendanceStatusId === AttendanceStatus.Completed) && (
				<div className="mt-14 text-center">
					<Link
						className="btn button-bg-samu-color-white"
						to={`/monitoramento-de-ocorrencia/acompanhamento/avaliar-atendimento/${attendanceId}`}
					>
						Avaliar atendimento
					</Link>
				</div>
			)}
		</div>
	);
}
