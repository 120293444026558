import { Fragment, useEffect, useRef, useState } from "react";
import classNames from "clsx";

import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";

import { FaPersonShelter, FaPersonWalkingArrowRight } from "react-icons/fa6";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { usePusher } from "@/hooks/usePusher";
import { useDebounce } from "@/hooks/useDebounce";
import { useAuth } from "@/modules/auth";
import { PlaceType, PlaceTypeMessages } from "@/enums/Place";

import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";

type Props = {
	showOccupyPlaceModal: boolean;
	handleCloseOccupyPlaceModal: () => void;
};

export function OccupyPlaceModal({ showOccupyPlaceModal, handleCloseOccupyPlaceModal }: Props) {
	const [places, setPlaces] = useState<PaginatedPlace>({} as PaginatedPlace);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);
	const [skeleton, setSkeleton] = useState(true);

	const { api } = useApi();
	const { currentAuth, setCurrentAuth } = useAuth();
	const { Toast, Swal } = useSwal();

	const forcePage = useRef(1);
	const isFirstRender = useRef(true);
	const debouncedSearch = useDebounce(search, 500);
	const pusher = usePusher();

	const { results } = places;

	const Loader = () => {
		return (
			<>
				{Array.from({ length: 10 }, (_, index) => (
					<Fragment key={`vehicle-status-modal-loader-${index}`}>
						<Skeleton height={40} />
					</Fragment>
				))}
			</>
		);
	};

	async function fetchPlaces(isSearch?: boolean, pageToUse?: number) {
		try {
			setSkeleton(true);

			const { data } = await api.get("/place", {
				params: {
					page: pageToUse || (isSearch ? 1 : page),
					search,
					place_statuses: [PlaceType.Free, PlaceType.Occupied],
				},
			});

			if (isSearch) {
				forcePage.current = 1;
				setPlaces(data);
			} else {
				const conbinedResults = [...(results || []), ...data.results];

				setPlaces({
					...data,
					results: conbinedResults,
				});
			}
		} catch (error) {
			console.error(error);
		} finally {
			setSkeleton(false);

			if (!results?.length && !skeleton && !search) {
				setTimeout(() => {
					handleCloseOccupyPlaceModal();
				}, 3000); // 3 seconds
			}
		}
	}

	async function handleOccupyPlace(id: string) {
		try {
			const { data: place } = await api.put<Place>(`/place/occupy/${id}`);

			setCurrentAuth({
				...currentAuth,
				place,
			} as User);

			Toast.fire({
				icon: "success",
				title: "Local ocupado com sucesso!",
			});

			handleCloseOccupyPlaceModal();
		} catch (error) {
			Toast.fire({
				icon: "error",
				title: "Erro ao ocupar local",
			});

			console.error(error);
		}
	}

	async function handleSwitchPlace(id: string) {
		try {
			Swal.fire({
				icon: "question",
				title: "Deseja trocar o local de ocupação?",
				showCancelButton: true,
			}).then((result) => {
				if (result.isConfirmed) {
					handleOccupyPlace(id);
				}
			});
		} catch (error) {
			console.error(error);
		}
	}

	async function handleVacatePlace(id: string) {
		try {
			await api.put<Place>(`/place/vacate/${id}`);

			setCurrentAuth({
				...currentAuth,
				place: null,
			} as User);

			Toast.fire({
				icon: "success",
				title: "Local desocupado com sucesso!",
			});

			handleCloseOccupyPlaceModal();
		} catch (error) {
			Toast.fire({
				icon: "error",
				title: "Erro ao desocupar local",
			});

			console.error(error);
		}
	}

	function handleNextPage() {
		if (forcePage.current !== page) {
			forcePage.current += 1;
			fetchPlaces(false, forcePage.current);
			return;
		}

		setPage(page + 1);
	}

	function handleRefreshPlaces() {
		const channel = pusher.subscribe(`private-change-places.${currentAuth?.urc_id}`);

		channel.bind("ChangePlaces", () => {
			fetchPlaces(true);
		});
	}

	useEffect(() => {
		forcePage.current = page;
		fetchPlaces();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		if (showOccupyPlaceModal) {
			fetchPlaces(true);
		}
	}, [debouncedSearch, showOccupyPlaceModal]);

	useEffect(() => {
		if (showOccupyPlaceModal) {
			handleRefreshPlaces();
		}

		return () => {
			pusher.unsubscribe(`private-change-places.${currentAuth?.urc_id}`);
		};
	}, []);

	return (
		<Modal
			size="xl"
			show={showOccupyPlaceModal}
			onHide={handleCloseOccupyPlaceModal}
			onExit={() => setSearch("")}
		>
			<Modal.Header className="justify-content-center">
				<h2 className="m-0">Locais</h2>
			</Modal.Header>

			<Modal.Body>
				<div className="d-flex justify-content-between align-items-center mb-7">
					<div>
						<h4 className="m-0">Profissional</h4>
						<span className="text-muted fs-5">Selecione o local que está ocupando</span>
					</div>

					<div>
						<SearchFormControl
							placeholder="Pesquisar por local"
							onChange={(event) => setSearch(event.target.value)}
						/>
					</div>
				</div>
				<div id="places-table-modal-body" className="overflow-y-scroll h-50vh pt-0">
					<InfiniteScroll
						dataLength={results?.length || 0}
						hasMore={places.meta?.current_page < places.meta?.last_page || false}
						next={handleNextPage}
						scrollableTarget="places-table-modal-body"
						loader={<Loader />}
					>
						<Table className="table-row-dashed table-row-gray-300 mb-0">
							<thead>
								<tr className="fw-bold fs-6">
									<th>Local</th>
									<th>Profissional</th>
									<th>Status</th>
									<th>Ações</th>
								</tr>
							</thead>

							<tbody>
								{!skeleton &&
									results?.map((place, index) => {
										const isCurrentUser = place?.user_id === currentAuth?.id;
										const currentUserAlreadyHasPlace =
											Object.entries(currentAuth?.place || {}).length > 0;
										const status =
											place?.place_status_id as keyof typeof PlaceTypeMessages;

										const isActive = status === PlaceType.Free;
										const isOccupied = status === PlaceType.Occupied;
										const isDisabled = status === PlaceType.Disabled;

										const handleClickPlace = currentUserAlreadyHasPlace
											? () => handleSwitchPlace(place?.id)
											: () => handleOccupyPlace(place?.id);

										function getActionButton() {
											if (isOccupied) {
												if (isCurrentUser) {
													return (
														<button
															className="border-0 rounded px-5 py-2 button-bg-light-color-gray-400 d-flex align-items-center justify-content-center gap-2 w-100"
															onClick={() =>
																handleVacatePlace(place?.id)
															}
														>
															<FaPersonWalkingArrowRight />
															Sair
														</button>
													);
												}

												return;
											}

											return (
												<button
													className="border-0 rounded px-5 py-2 button-bg-light-color-gray-400 d-flex align-items-center gap-2"
													onClick={handleClickPlace}
												>
													<FaPersonShelter />
													Ocupar
												</button>
											);
										}

										const actionButton = getActionButton();

										return (
											<tr key={`place-${index}`}>
												<td className="align-middle">{place?.name}</td>
												<td className="align-middle">
													{place?.user?.name}
												</td>
												<td className="align-middle">
													<Badge
														className={classNames("formatted-badge", {
															"badge-bg-green": isActive,
															"bg-samu": isOccupied,
															"badge-bg-gray": isDisabled,
														})}
														pill
													>
														{PlaceTypeMessages[status]}
													</Badge>
												</td>
												<td className="align-middle w-1px text-center">
													{actionButton}
												</td>
											</tr>
										);
									})}

								{skeleton && <TableBodySkeleton columns={4} />}

								{!results?.length && !skeleton && (
									<tr>
										<td className="text-center" colSpan={12}>
											<h2 className="mt-12 mb-0">Nenhum local encontrado</h2>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</InfiniteScroll>
				</div>

				<div className="mt-12 d-flex justify-content-center">
					<button
						className="btn button-bg-light-color-gray-400"
						onClick={handleCloseOccupyPlaceModal}
					>
						Fechar
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
