import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import { CiGlobe } from "react-icons/ci";
import { LuCalendarDays } from "react-icons/lu";

import { RoleMessages } from "@/enums/Role";
import { useDebounce } from "@/hooks/useDebounce";
import { useSwal } from "@/hooks/useSwal";
import { useApi } from "@/hooks/useApi";

import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { PaginationLinks } from "@/components/PaginationLinks";
import { AccessHistoryModal } from "@/components/Modals/AccessHistory";

export function AccessHistory() {
	const [history, setHistory] = useState<PaginatedAccessHistory>({} as PaginatedAccessHistory);
	const [accessHistory, setAccessHistory] = useState<AccessHistory>({} as AccessHistory);
	const [page, setPage] = useState(1);
	const [show, setShow] = useState(false);
	const [skeleton, setSkeleton] = useState(true);

	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 500);
	const { Toast, toastRequestErrors } = useSwal();

	const { api } = useApi();
	const forcePage = useRef(1);
	const isFirstRender = useRef(true);

	const { results } = history;

	const hasLocation = accessHistory.longitude && accessHistory.latitude;

	async function fetchAccessHistory(pageToUse?: number) {
		setSkeleton(true);

		try {
			const { data } = await api.get("/user-logs", {
				params: {
					page: pageToUse || page,
					search: debouncedSearch,
					start_date: startDate,
					end_date: endDate,
				},
			});

			setHistory(data);
		} catch (error: any) {
			console.error(error);

			if (error.isAxiosError && error.response.status === 422) {
				return toastRequestErrors(error.response.data.errors);
			}

			Toast.fire({
				icon: "error",
				title: "Erro ao realizar as alterações",
			});
		} finally {
			setSkeleton(false);
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchAccessHistory(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	function timeLogged(hour: Date | string | undefined) {
		const date = dayjs(hour).format("DD/MM/YYYY");
		const dateTime = dayjs(hour).format("HH:mm");

		const time = `${date} às ${dateTime}`;

		return time;
	}

	function handleShowModal(access: AccessHistory) {
		setAccessHistory(access);
		setShow(true);
	}

	function handleDate(date: [Date | null, Date | null]) {
		setStartDate(date[0]);
		setEndDate(date[1]);
	}

	useEffect(() => {
		forcePage.current = page;

		if (page) {
			fetchAccessHistory();
		}
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		if (!endDate) {
			return;
		}

		if (endDate) {
			forcePage.current = 1;
			fetchAccessHistory(1);
		}
	}, [endDate]);

	useEffect(() => {
		if (debouncedSearch) {
			forcePage.current = 1;
			fetchAccessHistory(1);
		}
	}, [debouncedSearch]);

	return (
		<Card>
			<Card.Header className="d-flex flex-between">
				<h3 className="fw-normal m-0">Histórico de acessos</h3>

				<div className="d-flex gap-2">
					<div>
						<DatePicker
							showIcon
							selectsRange
							dateFormat="dd/MM/yyyy"
							className="form-control"
							calendarIconClassname="h-100 position-absolute py-0 px-4 end-0"
							showPopperArrow={false}
							selected={new Date()}
							startDate={startDate}
							endDate={endDate}
							icon={<LuCalendarDays />}
							onChange={(dates) => handleDate(dates)}
						/>
					</div>

					<div>
						<SearchFormControl
							placeholder="Pesquisar usuário"
							onChange={(event) => setSearch(event.currentTarget.value)}
						/>
					</div>
				</div>
			</Card.Header>

			<Card.Body>
				<Table responsive className="table-row-dashed table-row-gray-300">
					<thead>
						<tr className="fw-bold fs-6">
							<th>Usuário</th>
							<th>Perfil</th>
							<th>CRU</th>
							<th>Data de acesso</th>
							<th>Dispositivo</th>
							<th>Localização</th>
						</tr>
					</thead>
					<tbody>
						{skeleton ? (
							<TableBodySkeleton columns={6} />
						) : (
							results?.map((access) => (
								<tr key={access.id}>
									<td>{access?.name}</td>
									<td>
										{
											RoleMessages[
												access?.role?.name as keyof typeof RoleMessages
											]
										}
									</td>

									<td>{access?.urgency_regulation_center?.name}</td>
									<td>{timeLogged(access?.logged_at)}</td>
									<td>{access?.user_agent}</td>

									<td className="w-1px">
										<Button
											variant="secondary"
											className="btn-icon btn-sm"
											onClick={() => handleShowModal(access)}
										>
											<CiGlobe className="text-gray-700 fs-2" />
										</Button>
									</td>
								</tr>
							))
						)}

						{!results?.length && !skeleton && (
							<tr>
								<td className="text-center" colSpan={12}>
									<h2 className="mt-12 mb-0">Não há registros de acessos</h2>
								</td>
							</tr>
						)}
					</tbody>
				</Table>

				<div className="d-flex justify-content-end mt-8">
					<PaginationLinks
						itemsPerPage={10}
						totalItems={history?.meta?.total}
						forcePage={forcePage.current - 1}
						changeSelectedPage={handleChangeSelectedPage}
					/>
				</div>
			</Card.Body>

			<AccessHistoryModal
				show={show}
				setShow={setShow}
				accessHistory={accessHistory}
				setAccessHistory={setAccessHistory}
				hasLocation={hasLocation}
			/>
		</Card>
	);
}
