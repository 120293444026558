import Yup from "@/utils/mixins/yup";
import { AttendanceType } from "@/enums/AttendanceType";

export const sceneRegisterSchema = Yup.object().shape({
	priority_type_id: Yup.string().when("closed", {
		is: false,
		then: Yup.string().required("Campo obrigatório"),
	}),
	support_needed: Yup.string().when("closed", {
		is: false,
		then: Yup.string().required("Campo obrigatório"),
	}),
	is_accident_at_work: Yup.string().when("closed", {
		is: false,
		then: Yup.string().required("Campo obrigatório"),
	}),
	conduct_types: Yup.array().when("closed", {
		is: false,
		then: Yup.array().min(1, "Campo obrigatório"),
	}),
	vacancy_type_id: Yup.string().when(["conduct_types", "attendanceType"], {
		is: (conductTypes: string, attendanceType: number) => {
			return (
				conductTypes.includes("Atendimento com Remoção") &&
				attendanceType === AttendanceType.SecondaryOccurrence
			);
		},
		then: (schema) => schema.required("Campo obrigatório"),
	}),
});
