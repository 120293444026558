import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "clsx";
import dayjs from "dayjs";

import { AiOutlineClear } from "react-icons/ai";
import { FaPlus, FaTrash } from "react-icons/fa";
import { ImArrowLeft2 } from "react-icons/im";

import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { ActionType, ActionTypeMessages } from "@/enums/ActionType";
import { PriorityType } from "@/enums/PriorityType";
import { AttendanceType } from "@/enums/AttendanceType";
import { NatureTypeMessages } from "@/enums/NatureType";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { usePusher } from "@/hooks/usePusher";
import { createSentence } from "@/utils/createSentence";
import { useWindowDimensions } from "@/hooks/useWindowDimensions";
import { natureTypesOptions } from "@/utils/options/natureTypes";
import { medicalRegulationSchema } from "@/utils/validation/medicalRegulationSchema";
import { medicalRegulationInitialValues } from "@/utils/initialValues/medicalRegulation";
import { filterSelectedDiagnosticHypotheses } from "@/utils/filterSelectedDiagnosticHypotheses";

import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";
import { DiagnosticHypothesesSelect } from "@/components/Selects/DiagnosticHypothesesSelect";
import { HistoryRegisterModal } from "@/components/Modals/HistoryRegisterModal";
import { VehicleStatusModal } from "@/components/Modals/VehicleStatusModal";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { FormattedBadge } from "@/components/Badges/FormattedBadge";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { UnitsSelect } from "@/components/Selects/UnitsSelect";
import { OccurrenceData } from "@/components/OccurrenceData";
import { LateralToolbar } from "@/components/LateralToolbar";
import { FormRadio } from "@/components/Radios/FormRadio";
import { RedAsterisk } from "@/components/RedAsterisk";
import { Separator } from "@/components/Separator";

type BaseCheckboxProps = {
	label: string;
	id: string;
	name: string;
};

type AlertValidationProps = {
	message: string;
};

type ReactSelectFilterOption<Option> = {
	readonly label: string;
	readonly value: string;
	readonly data: Option;
};

type KeyOfNatureTypeMessages = keyof typeof NatureTypeMessages;
type keyOfActionTypeMessages = keyof typeof ActionTypeMessages;

type KeyOfMedicalRegulationChecks = "action_details" | "supporting_organizations";

export function MedicalRegulation() {
	const [lastMedicalRegulation, setLastMedicalRegulation] = useState<MedicalRegulation>(
		{} as MedicalRegulation
	);
	const [diagnosticLabelIAM, setDiagnosticLabelIAM] = useState<string | null>(null);
	const [showHistoryRegisterModal, setShowHistoryRegisterModal] = useState(false);
	const [showVehicleStatusModal, setShowVehicleStatusModal] = useState(false);
	const [occurrence, setOccurrence] = useState<Attendable>({} as Attendable);
	const [attendanceType, setAttendanceType] = useState<number>();
	const [inAttendance, setInAttendance] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const { Swal, Toast, SwalToInAttendance, toastRequestErrors } = useSwal();
	const { attendanceId } = useParams();
	const navigate = useNavigate();
	const { api } = useApi();
	const { width } = useWindowDimensions();
	const pusher = usePusher();

	const attendance = occurrence?.attendance;
	const ticket = attendance?.ticket;
	const attendableId = attendance?.id;

	const alreadyHasMedicalRegulation = Object.keys(lastMedicalRegulation).length > 0;

	const handleShowHistoryRegisterModal = () => setShowHistoryRegisterModal(true);
	const handleCloseHistoryRegisterModal = () => setShowHistoryRegisterModal(false);

	const handleShowVehicleStatusModal = () => setShowVehicleStatusModal(true);
	const handleCloseVehicleStatusModal = () => setShowVehicleStatusModal(false);

	const BaseCheckbox = ({ label, id, name }: BaseCheckboxProps) => (
		<Col lg="auto" className="d-flex text-center gap-2">
			<input
				type="checkbox"
				value={label}
				id={id}
				name={name}
				onChange={formik.handleChange}
				checked={values[name as KeyOfMedicalRegulationChecks].includes(label)}
			/>

			<Form.Label className="m-0" htmlFor={id}>
				{label}
			</Form.Label>
		</Col>
	);

	const AlertValidation = ({ message }: AlertValidationProps) => (
		<div className="fv-plugins-message-container">
			<div className="fv-help-block">
				<span role="alert">{message}</span>
			</div>
		</div>
	);

	const formik = useFormik({
		validationSchema: medicalRegulationSchema,
		initialValues: medicalRegulationInitialValues,
		async onSubmit(values) {
			try {
				await api.post(`/medical-regulation/`, values);

				Toast.fire({
					icon: "success",
					title: "Registrado com sucesso",
				});

				navigate("/ocorrencias");
			} catch (error: any) {
				console.log(error);
				toastRequestErrors(error.response?.data.errors ?? {});
			}
		},
	});

	const { values, errors } = formik;

	const isDiagnosticHypothesesFieldsInvalid = values.diagnostic_hypotheses.some(
		({ nature_type_id, diagnostic_hypothesis_id }) =>
			!nature_type_id || !diagnostic_hypothesis_id
	);

	const isDisabledSubmitButton =
		inAttendance &&
		(formik.isSubmitting || !formik.isValid || isDiagnosticHypothesesFieldsInvalid);

	async function handleSearchAttendance() {
		const url =
			attendanceType === AttendanceType.PrimaryOccurrence
				? "/ticket/primary-attendance/"
				: "/ticket/secondary-attendance/";

		try {
			const { data } = await api.get(url + attendanceId);

			setOccurrence(data);
		} catch (error: any) {
			console.log(error);
			toastRequestErrors(error.response?.data.errors ?? {});
		}
	}

	async function handleStartAttendance() {
		try {
			await api.post(`medical-regulation/start-attendance/${attendanceId}`);

			handleSearchLastMedicalRegulation();
			setInAttendance(true);
		} catch (error: any) {
			const message = error.response.data.message;

			SwalToInAttendance(message);
		}
	}

	async function handleCheckAttendance() {
		try {
			const { data } = await api.get(`/attendance/check/medical-regulation/${attendanceId}`);

			setInAttendance(true);
			setAttendanceType(data?.ticket_type_id);
		} catch (error: any) {
			console.log(error);

			setAttendanceType(error.response.data.ticket_type_id);
			setInAttendance(false);
		}
	}

	async function handleCloseAttendance() {
		if (!inAttendance) {
			return navigate("/ocorrencias");
		}

		Swal.fire({
			title: "As informações não salvas serão perdidas. Deseja continuar?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then(async (result: any) => {
			if (result.isConfirmed) {
				try {
					await api.put(`attendance/${attendanceId}/close`);

					Toast.fire({
						icon: "success",
						title: "Atendimento fechado com sucesso",
					});
				} catch (error: any) {
					console.log(error);

					toastRequestErrors(error.response?.data.errors ?? {});
				} finally {
					navigate("/ocorrencias");
				}
			}
		});
	}

	async function handleSearchLastMedicalRegulation() {
		try {
			const { data } = await api.get(`/medical-regulation/latest/${attendableId}`);

			setLastMedicalRegulation(data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	const selectedUnitDestination = {
		value: values.destination_unit_id || occurrence.unit_destination?.id,
		label: values.destination_unit_label || occurrence.unit_destination?.name,
	};

	function handleChangeDestinationUnit(option: ReactSelectOption) {
		formik.setFieldValue("destination_unit_id", String(option.value));
		formik.setFieldValue("destination_unit_label", option.label);
	}

	function handleAddDiagnosticHypothesis() {
		const defaultValues = {
			...medicalRegulationInitialValues.diagnostic_hypotheses[0],
		};

		formik.setFieldValue("diagnostic_hypotheses", [
			...values.diagnostic_hypotheses,
			defaultValues,
		]);
	}

	function handleDeleteDiagnosticHypothesis(indexToRemove: number) {
		const filteredDiagnosticHypotheses = values.diagnostic_hypotheses.filter(
			(_, index) => index !== indexToRemove
		);

		formik.setFieldValue("diagnostic_hypotheses", filteredDiagnosticHypotheses);
	}

	function handleChangeNatureTypeDiagnosticHypothesis(index: number, option: ReactSelectOption) {
		const { value, label } = option;

		formik.setFieldValue(`diagnostic_hypotheses[${index}].nature_type_id`, value);
		formik.setFieldValue(`diagnostic_hypotheses[${index}].nature_type_label`, label);

		formik.setFieldValue(`diagnostic_hypotheses[${index}].diagnostic_hypothesis_id`, "");
		formik.setFieldValue(`diagnostic_hypotheses[${index}].diagnostic_hypothesis_label`, "");
	}

	function handleCleanRadiosIAM(index: string | number) {
		formik.setFieldValue(`diagnostic_hypotheses[${index}].recommended`, "");
		formik.setFieldValue(`diagnostic_hypotheses[${index}].applied`, "");
	}

	function handleDisplayIAM(diagnosticHypothesisLabel: string) {
		const IAM_LABEL = "IAM";
		const IAM_LABEL_SECONDARY = "INFARTO AGUDO DO MIOCÁRDIO (IAM)";

		if (
			diagnosticHypothesisLabel === IAM_LABEL ||
			diagnosticHypothesisLabel === IAM_LABEL_SECONDARY
		) {
			setDiagnosticLabelIAM(diagnosticHypothesisLabel);
		}
	}

	function handleChangeDiagnosticHypotheses(option: ReactSelectOption, index: number) {
		formik.setFieldValue(
			`diagnostic_hypotheses[${index}].diagnostic_hypothesis_id`,
			(option as ReactSelectOption).value
		);
		formik.setFieldValue(
			`diagnostic_hypotheses[${index}].diagnostic_hypothesis_label`,
			(option as ReactSelectOption).label
		);

		handleDisplayIAM((option as ReactSelectOption).label);
	}

	function handleRefreshClosedAttendance() {
		const channel = pusher.subscribe(`private-cancel-attendance.refresh.${attendableId}`);

		channel.bind("RefreshCancelAttendance", () => {
			navigate("/ocorrencias");
		});
	}

	useEffect(() => {
		if (attendanceId) {
			handleCheckAttendance();
		}
	}, [attendanceId]);

	useEffect(() => {
		if (attendanceType) {
			handleSearchAttendance();
		}
	}, [attendanceType]);

	useEffect(() => {
		if (attendableId) {
			formik.setFieldValue("attendance_id", attendableId);
			handleSearchLastMedicalRegulation();

			handleRefreshClosedAttendance();
		}

		return () => {
			pusher.unsubscribe(`private-cancel-attendance.refresh.${attendableId}`);
		};
	}, [attendableId]);

	const showNotificationHistoryRegister = Boolean(
		attendance?.observations?.length ||
			attendance?.medical_regulations?.length ||
			attendance?.radio_operation?.notes?.length
	);

	function submitButtonText() {
		if (inAttendance) {
			return "Registrar";
		}

		if (alreadyHasMedicalRegulation) {
			return "Iniciar nova regulação";
		}

		return "Iniciar regulação";
	}

	return (
		<>
			<LateralToolbar>
				<LateralToolbar.Button onClick={handleShowVehicleStatusModal}>
					Status das <br /> viaturas
				</LateralToolbar.Button>

				<LateralToolbar.Button
					onClick={handleShowHistoryRegisterModal}
					showNotificationIndicator={showNotificationHistoryRegister}
				>
					Histórico de registros
				</LateralToolbar.Button>
			</LateralToolbar>

			<Card className="mb-8">
				{isLoading ? (
					<div className="d-flex flex-center h-80vh">
						<Spinner
							variant="samu"
							animation="border"
							style={{ width: "4rem", height: "4rem" }}
						/>
					</div>
				) : (
					<>
						<Card.Header>
							<div
								className={classNames(
									"d-flex justify-content-between align-items-center w-100",
									width >= 560 && "default-form-card-header"
								)}
							>
								<div className={classNames(width <= 560 && "d-none")}>
									{dayjs(ticket?.opening_at).format(
										"[Aberto em] DD/MM/YYYY [às] HH:mm[hr]"
									)}
								</div>

								<h2 className="m-0 text-nowrap">Regulação médica</h2>

								<div>
									<button
										type="button"
										className="d-flex align-items-center btn button-bg-light-color-gray-400 gap-2 w-100px"
										onClick={handleCloseAttendance}
									>
										<ImArrowLeft2 />
										Voltar
									</button>
								</div>
							</div>
						</Card.Header>

						<Card.Body>
							<OccurrenceData
								attendanceType={attendanceType}
								attendable={occurrence}
							/>

							<Separator />

							{alreadyHasMedicalRegulation && (
								<>
									<h3 className="mb-8">APH 1º - Regulação</h3>

									<Col className="d-flex flex-column gap-2 mb-10">
										<h4>Histórico regulação médica</h4>

										<Col>
											{dayjs(lastMedicalRegulation?.created_at).format(
												"DD/MM/YYYY [às] HH:mm"
											)}{" "}
											-{" "}
											<span className="fw-bold">
												{`${lastMedicalRegulation?.createdBy?.name} - CRM:${lastMedicalRegulation?.createdBy?.council_number}`}
											</span>
										</Col>

										{lastMedicalRegulation?.diagnostic_hypotheses?.map(
											(diagnosticHypothesis, index) => (
												<Row key={`diagnostic-hypothesis-${index}`}>
													<div
														className={classNames(
															width <= 600 && "w-100px"
														)}
													>
														<Col lg={3}>
															<span className="fw-bold">
																Natureza:
															</span>{" "}
															{NatureTypeMessages[
																diagnosticHypothesis.pivot
																	.nature_type_id as KeyOfNatureTypeMessages
															] ?? "-"}
														</Col>
													</div>

													<Col>
														<span className="fw-bold">HD:</span>{" "}
														{diagnosticHypothesis.name ?? "-"}
													</Col>
												</Row>
											)
										)}

										<Col>
											<span className="fw-bold">Regulação:</span>{" "}
											{lastMedicalRegulation.medical_regulation}
										</Col>

										<Col>
											{lastMedicalRegulation.action_type_id ===
											ActionType.WithIntervention ? (
												<span className="fw-bold">
													Ação com Intervenção:
												</span>
											) : (
												<span className="fw-bold">
													Ação{" "}
													<span className="text-lowercase">
														{
															ActionTypeMessages[
																lastMedicalRegulation?.action_type_id as keyOfActionTypeMessages
															]
														}
													</span>
													{lastMedicalRegulation?.action_details.length
														? ": "
														: ""}
												</span>
											)}

											{createSentence(
												lastMedicalRegulation.action_details ?? []
											)}
										</Col>

										<Row>
											<Col lg={3}>
												<span className="fw-bold">Prioridade:</span>{" "}
												{lastMedicalRegulation.priority_type_id ? (
													<FormattedBadge
														attribute={
															lastMedicalRegulation.priority_type_id
														}
													/>
												) : (
													"Sem prioridade"
												)}
											</Col>

											<Col>
												<span className="fw-bold">Código:</span>{" "}
												{lastMedicalRegulation.vehicle_movement_code_id ? (
													<FormattedBadge
														attribute={
															lastMedicalRegulation.vehicle_movement_code_id
														}
														isVehicleMovementCode
													/>
												) : (
													"Sem código de movimento"
												)}
											</Col>
										</Row>
									</Col>
								</>
							)}

							{inAttendance && (
								<>
									<Form
										id="medical-regulation-form"
										onSubmit={formik.handleSubmit}
										className="mb-4"
									>
										<FormControlWithCharactersCount
											as="textarea"
											className={classNames(
												"form-control-with-characters-count",
												errors.medical_regulation && "is-invalid"
											)}
											id="medical-regulation"
											name="medical_regulation"
											label="Regulação médica"
											placeholder="Descreva"
											onChange={formik.handleChange}
											value={values.medical_regulation}
											labelClassName="fw-bold"
											maxLength={5000}
											isRequired
											required
										/>

										{errors.medical_regulation && (
											<AlertValidation message={errors.medical_regulation} />
										)}

										<div className="mt-4">
											{values.diagnostic_hypotheses.map(
												(diagnosticHypothesis, index) => {
													const diagnosticHypothesisLabel =
														diagnosticHypothesis.diagnostic_hypothesis_label;

													const isIAM =
														diagnosticLabelIAM ===
														diagnosticHypothesisLabel;

													return (
														<Row key={`diagnostic-hipothesis-${index}`}>
															<Col lg={4}>
																<Form.Label
																	className="fw-bold"
																	htmlFor="nature-type"
																>
																	Natureza <RedAsterisk />
																</Form.Label>

																<ReactSelect
																	inputId="nature-type"
																	options={natureTypesOptions}
																	placeholder="Selecione uma natureza"
																	onChange={(option) =>
																		handleChangeNatureTypeDiagnosticHypothesis(
																			index,
																			option as ReactSelectOption
																		)
																	}
																	defaultValue={natureTypesOptions.find(
																		(option) =>
																			option.value ===
																			diagnosticHypothesis.nature_type_id
																	)}
																	isInvalid={
																		!diagnosticHypothesis.nature_type_id
																	}
																/>

																{!diagnosticHypothesis.nature_type_id && (
																	<span className="text-danger">
																		Campo obrigatório
																	</span>
																)}
															</Col>

															<Col lg={5}>
																<Form.Label
																	className="fw-bold"
																	htmlFor="diagnostic-hypothesis"
																>
																	Hipótese diagnóstica{" "}
																	<RedAsterisk />
																</Form.Label>

																<DiagnosticHypothesesSelect
																	inputId="diagnostic-hypothesis"
																	natureTypes={
																		diagnosticHypothesis.nature_type_id
																	}
																	onChange={(option: any) =>
																		handleChangeDiagnosticHypotheses(
																			option,
																			index
																		)
																	}
																	isInvalid={
																		!diagnosticHypothesis.diagnostic_hypothesis_id
																	}
																	isDisabled={
																		!diagnosticHypothesis.nature_type_id
																	}
																	filterOption={(
																		candidate: ReactSelectFilterOption<unknown>,
																		input: string
																	) =>
																		filterSelectedDiagnosticHypotheses(
																			candidate,
																			input,
																			values
																		)
																	}
																/>

																{!diagnosticHypothesis.diagnostic_hypothesis_id && (
																	<span className="text-danger">
																		Campo obrigatório
																	</span>
																)}

																{isIAM && (
																	<Col className="my-3">
																		<div className="fw-bold mb-2">
																			Uso de trombolíticos
																			indicado?
																		</div>

																		<div className="d-flex gap-3">
																			<div className="d-flex flex-column gap-3 justify-content-center">
																				<FormGroupRadio
																					className="d-grid grid-template-col-10rem-auto"
																					requestedValue={
																						diagnosticHypothesis.recommended
																					}
																					{...formik.getFieldProps(
																						`diagnostic_hypotheses[${index}].recommended`
																					)}
																				>
																					<FormRadio
																						label="Recomendado"
																						value="2"
																						id={`IAM-recommended-medical-regulation-${index}`}
																					/>
																					<FormRadio
																						label="Não recomendado"
																						value="1"
																						className="text-nowrap"
																						id={`IAM-not-recommended-medical-regulation-${index}`}
																					/>
																				</FormGroupRadio>

																				{diagnosticHypothesis.recommended && (
																					<FormGroupRadio
																						className="d-grid grid-template-col-10rem-auto"
																						requestedValue={
																							diagnosticHypothesis.applied
																						}
																						{...formik.getFieldProps(
																							`diagnostic_hypotheses[${index}].applied`
																						)}
																					>
																						<FormRadio
																							label="Aplicado"
																							value="2"
																							id={`IAM-applied-medical-regulation-${index}`}
																						/>
																						<FormRadio
																							label="Não aplicado"
																							value="1"
																							id={`IAM-not-applied-medical-regulation-${index}`}
																						/>
																					</FormGroupRadio>
																				)}
																			</div>

																			<OverlayTrigger
																				overlay={
																					<Tooltip>
																						Limpar
																					</Tooltip>
																				}
																			>
																				<div className="d-flex align-items-center">
																					<Button
																						type="button"
																						variant="secondary"
																						className="btn-icon h-38px w-38px"
																						onClick={() =>
																							handleCleanRadiosIAM(
																								index
																							)
																						}
																					>
																						<AiOutlineClear className="text-gray-700" />
																					</Button>
																				</div>
																			</OverlayTrigger>
																		</div>
																	</Col>
																)}
															</Col>

															<Col className="d-flex gap-2 pt-8">
																<Button
																	type="button"
																	variant="secondary"
																	className="btn-icon h-38px w-38px"
																	onClick={
																		handleAddDiagnosticHypothesis
																	}
																>
																	<FaPlus className="text-gray-700" />
																</Button>

																{index !== 0 && (
																	<Button
																		type="button"
																		variant="secondary"
																		className="btn-icon h-38px w-38px"
																		onClick={() =>
																			handleDeleteDiagnosticHypothesis(
																				index
																			)
																		}
																	>
																		<FaTrash className="text-gray-700" />
																	</Button>
																)}
															</Col>
														</Row>
													);
												}
											)}
										</div>

										<Separator />

										<h3 className="mb-4">Ações</h3>

										<FormGroupRadio
											name="consciousness_level_id"
											label="Nível de Consciência"
											className="d-flex flex-wrap mb-4"
											labelClassName="fs-4 fw-bold"
											requestedValue={values.consciousness_level_id}
											onChange={formik.handleChange}
											inline
										>
											<FormRadio id="normal" label="Normal" value="1" />

											<FormRadio id="confused" label="Confuso" value="2" />

											<FormRadio id="torporous" label="Torporoso" value="3" />

											<FormRadio
												id="unconscious"
												label="Inconsciente"
												value="4"
											/>
										</FormGroupRadio>

										<FormGroupRadio
											name="respiration_type_id"
											label="Respiração"
											className="d-flex flex-wrap mb-4"
											labelClassName="fs-4 fw-bold"
											requestedValue={values.respiration_type_id}
											onChange={formik.handleChange}
											inline
										>
											<FormRadio
												id="does-not-breathe"
												label="Não Respira"
												value="1"
											/>

											<FormRadio
												id="normal-respiration"
												label="Normal"
												value="2"
											/>

											<FormRadio
												id="noisy-dyspnea"
												label="Ruidosa / Dispneia"
												value="3"
											/>

											<FormRadio
												id="obstructed-airway"
												label="Via aérea obstruída"
												value="4"
											/>

											<FormRadio
												id="acessible-airway"
												label="Via aérea pérvia"
												value="5"
											/>
										</FormGroupRadio>

										<FormGroupRadio
											name="action_type_id"
											label="Tipo de ação"
											labelClassName="fs-4 fw-bold"
											requestedValue={values.action_type_id}
											onChange={formik.handleChange}
											isRequired
											inline
										>
											<FormRadio
												id="with-intervention"
												label="Com intervenção"
												value="1"
												onChange={() =>
													formik.setFieldValue("action_details", [])
												}
											/>

											<FormRadio
												id="without-intervention"
												label="Sem intervenção"
												value="2"
												onChange={() =>
													formik.setFieldValue("action_details", [])
												}
											/>

											<FormRadio
												id="awaiting-for-return"
												label="Aguardando retorno"
												value="3"
												onChange={() =>
													formik.setFieldValue("action_details", [])
												}
											/>

											<FormRadio
												id="awaiting-for-vacancy"
												label="Aguardando vaga"
												value="4"
												onChange={() =>
													formik.setFieldValue("action_details", [])
												}
											/>
										</FormGroupRadio>

										{errors.action_type_id && (
											<AlertValidation message={errors.action_type_id} />
										)}

										<Row className="my-2 px-1">
											{Number(values.action_type_id) ===
												ActionType.WithIntervention && (
												<>
													<BaseCheckbox
														label="USB"
														id="basic-support-unit"
														name="action_details"
													/>

													<BaseCheckbox
														label="USA"
														id="advanced-support-unit"
														name="action_details"
													/>

													<BaseCheckbox
														label="Motolâncias"
														id="motolances"
														name="action_details"
													/>

													<BaseCheckbox
														label="Aeromédico"
														id="aeromedical"
														name="action_details"
													/>

													<BaseCheckbox
														label="USI"
														id="intermediate-support-unit"
														name="action_details"
													/>
												</>
											)}

											{Number(values.action_type_id) ===
												ActionType.WithoutIntervention && (
												<>
													<BaseCheckbox
														label="Orientação"
														id="orientation"
														name="action_details"
													/>

													<BaseCheckbox
														label="Informação"
														id="information"
														name="action_details"
													/>

													<BaseCheckbox
														label="QTA (Cancelamento)"
														id="qta-cancellation"
														name="action_details"
													/>

													<BaseCheckbox
														label="Meios Próprios"
														id="own-means"
														name="action_details"
													/>

													<BaseCheckbox
														label="Trote"
														id="prank-call"
														name="action_details"
													/>

													<BaseCheckbox
														label="Apoio"
														id="support"
														name="action_details"
													/>
												</>
											)}

											{errors.action_details && (
												<span className="text-danger">
													{errors.action_details}
												</span>
											)}
										</Row>

										<Col lg={3} className="mt-2 mb-4">
											<Form.Label
												htmlFor="destination-unit"
												className="fs-4 fw-bold"
											>
												Unidade destino
											</Form.Label>

											<UnitsSelect
												inputId="destination-unit"
												value={
													(occurrence.unit_destination?.id ||
														values.destination_unit_id) &&
													selectedUnitDestination
												}
												onChange={(option) =>
													handleChangeDestinationUnit(
														option as ReactSelectOption
													)
												}
											/>
										</Col>

										<FormGroupRadio
											name="priority_type_id"
											label="Prioridade"
											className="d-flex flex-wrap mb-4"
											labelClassName="fs-4 fw-bold"
											requestedValue={values.priority_type_id}
											onChange={formik.handleChange}
											isRequired={
												Number(values.action_type_id) ===
												ActionType.WithIntervention
											}
											inline
										>
											<FormRadio
												id="red"
												label={
													<FormattedBadge attribute={PriorityType.Red} />
												}
												value="5"
											/>

											<FormRadio
												id="orange"
												label={
													<FormattedBadge
														attribute={PriorityType.Orange}
													/>
												}
												value="4"
											/>

											<FormRadio
												id="yellow"
												label={
													<FormattedBadge
														attribute={PriorityType.Yellow}
													/>
												}
												value="3"
											/>

											<FormRadio
												id="green"
												label={
													<FormattedBadge
														attribute={PriorityType.Green}
													/>
												}
												value="2"
											/>

											<FormRadio
												id="blue"
												label={
													<FormattedBadge attribute={PriorityType.Blue} />
												}
												value="1"
											/>
										</FormGroupRadio>

										{errors.priority_type_id && (
											<AlertValidation message={errors.priority_type_id} />
										)}

										<Row>
											<FormGroupRadio
												name="vehicle_movement_code_id"
												label="Código de Deslocamento da VTR"
												className="d-flex flex-wrap mb-4"
												labelClassName="fs-4 fw-bold"
												requestedValue={values.vehicle_movement_code_id}
												onChange={formik.handleChange}
												isRequired={
													Number(values.action_type_id) ===
													ActionType.WithIntervention
												}
												inline
											>
												<FormRadio
													id="code-3"
													label={
														<span className="badge badge-red">
															Código 3
														</span>
													}
													value="3"
												/>

												<FormRadio
													id="code-2"
													label={
														<span className="badge badge-yellow">
															Código 2
														</span>
													}
													value="2"
												/>

												<FormRadio
													id="code-1"
													label={
														<span className="badge badge-green">
															Código 1
														</span>
													}
													value="1"
												/>
											</FormGroupRadio>

											{errors.vehicle_movement_code_id && (
												<span className="text-danger">
													{errors.vehicle_movement_code_id}
												</span>
											)}
										</Row>

										<Form.Label className="fs-4 fw-bold">
											Apoio de outros órgãos
										</Form.Label>

										<Row>
											<BaseCheckbox
												label="Bombeiros"
												id="firefighters"
												name="supporting_organizations"
											/>

											<BaseCheckbox
												label="Guarda municipal"
												id="municipal-guard"
												name="supporting_organizations"
											/>

											<BaseCheckbox
												label="PM"
												id="military-police"
												name="supporting_organizations"
											/>

											<BaseCheckbox
												label="Agente de trânsito"
												id="traffic-agent"
												name="supporting_organizations"
											/>

											<BaseCheckbox
												label="PRE"
												id="state-highway-police"
												name="supporting_organizations"
											/>

											<BaseCheckbox
												label="PRF"
												id="federal-highway-police-support"
												name="supporting_organizations"
											/>

											<BaseCheckbox
												label="Perícia forence"
												id="forensics"
												name="supporting_organizations"
											/>

											<BaseCheckbox
												label="CIOPAER"
												id="ciopaer"
												name="supporting_organizations"
											/>
										</Row>
									</Form>
								</>
							)}

							<div className="d-flex justify-content-center mt-16 gap-4">
								{inAttendance && (
									<button
										type="button"
										className="btn button-bg-light-color-gray-400 gap-2 w-100px"
										title="Ao clicar o atendimento voltará ao status anterior e ficará disponível para que outros usuários possam atendê-lo."
										onClick={handleCloseAttendance}
									>
										Fechar
									</button>
								)}

								<button
									type={inAttendance ? "submit" : "button"}
									className="btn button-bg-samu-color-white"
									onClick={!inAttendance ? handleStartAttendance : () => {}}
									form="medical-regulation-form"
									disabled={isDisabledSubmitButton}
								>
									{formik.isSubmitting ? (
										<Spinner animation="border" size="sm" className="mx-8" />
									) : (
										submitButtonText()
									)}
								</button>
							</div>
						</Card.Body>
					</>
				)}
			</Card>

			<VehicleStatusModal
				showVehicleStatusModal={showVehicleStatusModal}
				handleCloseVehicleStatusModal={handleCloseVehicleStatusModal}
				handleShowVehicleStatusModal={handleShowVehicleStatusModal}
			/>

			<HistoryRegisterModal
				showHistoryRegisterModal={showHistoryRegisterModal}
				handleCloseHistoryRegisterModal={handleCloseHistoryRegisterModal}
				attendanceId={attendanceId as string}
			/>
		</>
	);
}
