import { useEffect, useRef, useState } from "react";

import Card from "react-bootstrap/Card";
import { FaFilter } from "react-icons/fa6";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { useDebounce } from "@/hooks/useDebounce";
import { useCanAccess } from "@/hooks/useCanAccess";
import { VehicleStatus } from "@/enums/VehicleStatus";
import { get } from "@/utils/tracking/accessors";
import { Role } from "@/enums/Role";

import { GoogleMaps } from "@/components/Tracking/Maps/GoogleMaps";
import { OpenStreetMap } from "@/components/Tracking/Maps/OpenStreetMap";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { VehicleStatusesFilterModal } from "@/components/Modals/VehicleStatusesFilterModal";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const DEFAULT_OPTIONS = get().defaultOptions();

export function Tracking() {
	const { api } = useApi();
	const { currentAuth } = useAuth();
	const { can, roleIs } = useCanAccess();

	const interval = useRef<NodeJS.Timeout | null>(null);
	const [trackingData, setTrackingData] = useState<VehicleTrackingData[]>([]);
	const [search, setSearch] = useState("");
	const [bases, setBases] = useState<BaseOrUnit[]>([]);
	const [vehicleStatusesToFilter, setVehicleStatusesToFilter] = useState<number[]>([]);
	const [showVehicleStatusesFilterModal, setShowVehicleStatusesFilterModal] = useState(false);

	const debouncedSearch = useDebounce(search, 700);

	const abortController = useRef(new AbortController());
	const isRequestingTrackingData = useRef(false);

	const handleShowVehicleStatusesFilterModal = () => setShowVehicleStatusesFilterModal(true);
	const handleCloseVehicleStatusesFilterModal = () => setShowVehicleStatusesFilterModal(false);

	const DEFAULT_REMAINING_SECONDS = 30;

	const defaultOptions = {
		latitude: DEFAULT_OPTIONS.latitude,
		longitude: DEFAULT_OPTIONS.longitude,
		zoom: DEFAULT_OPTIONS.zoom,
	};

	const { manager, radioOperator } = Role;
	const needsToFilterVehicles = roleIs([manager, radioOperator]);

	const managerAndRadioOperationStatuses = [
		VehicleStatus.Active,
		VehicleStatus.Solicited,
		VehicleStatus.Committed,
		VehicleStatus.Unavailable,
	];

	const showNoCommunicationIndicator = false;
	const statuses: MapVehicleStatuses = needsToFilterVehicles
		? managerAndRadioOperationStatuses
		: "all";

	const defaultProps = {
		defaultOptions,
		trackingData,
		requestTrackingData,
		showNoCommunicationIndicator,
		statuses,
		bases,
		fetchAllBases,
		debouncedSearch,
		vehicleStatusesToFilter,
	};

	async function requestTrackingData() {
		try {
			abortController.current.abort();
			abortController.current = new AbortController();
			isRequestingTrackingData.current = true;

			const url = "/vehicles-tracking/all-vehicles-current-location";

			const { data } = await api.get<VehicleTrackingData[]>(url, {
				params: {
					...(needsToFilterVehicles && {
						vehicle_statuses_to_search: managerAndRadioOperationStatuses,
					}),
				},
				signal: abortController.current.signal,
			});

			setTrackingData(data);
		} catch (error) {
			console.error(error);
		} finally {
			isRequestingTrackingData.current = false;
		}
	}

	async function fetchAllBases() {
		try {
			const { data } = await api.get("/bases/tracking-bases");

			setBases(data.results);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		fetchAllBases();

		if (interval.current) {
			clearInterval(interval.current);
		}

		interval.current = setInterval(() => {
			if (!isRequestingTrackingData.current) {
				requestTrackingData();
			}
		}, DEFAULT_REMAINING_SECONDS * 1000);

		return () => {
			if (interval.current) {
				clearInterval(interval.current);
			}

			abortController.current.abort();
		};
	}, [currentAuth]);

	const mapKey = currentAuth?.current_role?.id;

	return (
		<Card className="bg-transparent">
			<div className="mb-4 d-flex justify-content-between align-items-center gap-2">
				<SearchFormControl
					placeholder="Pesquisar veículo"
					onChange={(event) => setSearch(event.target.value)}
				/>

				<button
					className="d-flex align-items-center gap-4 btn button-bg-white-color-samu"
					onClick={handleShowVehicleStatusesFilterModal}
				>
					<FaFilter /> Filtrar
				</button>
			</div>

			<Card.Body className="p-0" style={{ height: "75vh" }}>
				{can([manager]) && GOOGLE_MAPS_API_KEY ? (
					<GoogleMaps key={mapKey} {...defaultProps} />
				) : (
					<OpenStreetMap key={mapKey} {...defaultProps} />
				)}
			</Card.Body>

			<VehicleStatusesFilterModal
				showVehicleStatusesFilterModal={showVehicleStatusesFilterModal}
				handleCloseVehicleStatusesFilterModal={handleCloseVehicleStatusesFilterModal}
				setVehicleStatusesToFilter={setVehicleStatusesToFilter}
				vehicleStatusesToFilter={vehicleStatusesToFilter}
			/>
		</Card>
	);
}
