import { Role } from "@/enums/Role";

const routes = {
	DASHBOARD: "/dashboard",
	ADMIN_DASHBOARD: "/dashboard/admin",

	OCORRENCIAS: "/ocorrencias",
	NOVA_OCORRENCIA: "/ocorrencias/nova-ocorrencia",
	REGISTRO_DE_CENA: "/registro-de-cena",
	REGULACAO_MEDICA: "/regulacao-medica",
	RESUMO: "/resumo",
	VISUALIZAR_CHAMADO: "/visualizar-chamado",

	VER_PERFIL: "/ver-perfil",
	USUARIOS: "/usuarios",
	USUARIOS_CADASTRO: "/usuarios/cadastro",

	PROFISSIONAIS: "/profissionais",
	PROFISSIONAIS_CADASTRO: "/profissionais/cadastro",

	VIATURAS: "/controle-de-frota/viaturas",
	EQUIPAMENTOS: "/controle-de-frota/equipamentos",
	RADIO_OPERADOR_INDEX: "/controle-de-frota/ocorrencias",
	RADIO_OPERADOR_FORMULARIO: "/radio-operador",

	MAPA_DE_VIATURA: "/rastreamento/mapa-de-viaturas",
	HISTORICO_DE_PERCURSOS: "/rastreamento/historico-de-percursos",

	BASES: "/bases",
	BASES_CADASTRO: "/bases/cadastro",
	UNIDADES: "/unidades",
	UNIDADES_CADASTRO: "/unidades/cadastro",

	CONSULTAS: "/relatorios/consultas",
	PLANTAO: "/relatorios/plantao",
	BPA: "/relatorios/bpa",
	POWER_BI: "/relatorios/power-bi",

	GRUPOS_REGIONAIS: "/configuracoes/grupos-regionais",
	RECADOS: "/configuracoes/recados",
	TOKEN: "/configuracoes/token",
	GERENCIAMENTO: "/configuracoes/gerenciamento",
	HISTORICO_DE_ACESSO: "/configuracoes/historico-de-acessos",
	DISPOSITIVOS_MOVEIS: "/configuracoes/dispositivos-moveis",
	CONFIGURACOES_DE_MONITORAMENTO: "/configuracoes/monitoramento",
};

const {
	OCORRENCIAS,
	NOVA_OCORRENCIA,
	VER_PERFIL,
	USUARIOS,
	USUARIOS_CADASTRO,
	VIATURAS,
	// EQUIPAMENTOS,
	RADIO_OPERADOR_INDEX,
	RADIO_OPERADOR_FORMULARIO,
	RECADOS,
	BASES,
	BASES_CADASTRO,
	UNIDADES,
	UNIDADES_CADASTRO,
	CONSULTAS,
	PLANTAO,
	BPA,
	REGISTRO_DE_CENA,
	REGULACAO_MEDICA,
	RESUMO,
	ADMIN_DASHBOARD,
	TOKEN,
	GERENCIAMENTO,
	MAPA_DE_VIATURA,
	HISTORICO_DE_PERCURSOS,
	DASHBOARD,
	PROFISSIONAIS,
	PROFISSIONAIS_CADASTRO,
	DISPOSITIVOS_MOVEIS,
	POWER_BI,
	HISTORICO_DE_ACESSO,
	VISUALIZAR_CHAMADO,
	GRUPOS_REGIONAIS,
	CONFIGURACOES_DE_MONITORAMENTO,
} = routes;

export const routesPerRole = {
	[Role.superAdmin]: Object.values(routes),
	[Role.admin]: [
		DASHBOARD,
		ADMIN_DASHBOARD,
		OCORRENCIAS,
		REGULACAO_MEDICA,
		VIATURAS,
		RADIO_OPERADOR_INDEX,
		RADIO_OPERADOR_FORMULARIO,
		NOVA_OCORRENCIA,
		RECADOS,
		VER_PERFIL,
		USUARIOS,
		USUARIOS_CADASTRO,
		BASES,
		BASES_CADASTRO,
		UNIDADES,
		UNIDADES_CADASTRO,
		CONSULTAS,
		PLANTAO,
		BPA,
		RESUMO,
		GERENCIAMENTO,
		MAPA_DE_VIATURA,
		HISTORICO_DE_PERCURSOS,
		HISTORICO_DE_ACESSO,
		DISPOSITIVOS_MOVEIS,
		REGISTRO_DE_CENA,
		PROFISSIONAIS,
		PROFISSIONAIS_CADASTRO,
		CONFIGURACOES_DE_MONITORAMENTO,
		POWER_BI,
	],
	[Role.manager]: [
		DASHBOARD,
		TOKEN,
		VER_PERFIL,
		MAPA_DE_VIATURA,
		HISTORICO_DE_PERCURSOS,
		BPA,
		POWER_BI,
		GRUPOS_REGIONAIS,
		HISTORICO_DE_ACESSO,
		DISPOSITIVOS_MOVEIS,
		CONFIGURACOES_DE_MONITORAMENTO,
	],
	[Role.teamLeader]: [
		OCORRENCIAS,
		NOVA_OCORRENCIA,
		CONSULTAS,
		VER_PERFIL,
		REGISTRO_DE_CENA,
		REGULACAO_MEDICA,
		RESUMO,
		PLANTAO,
		POWER_BI,
	],
	[Role.coordinator]: [CONSULTAS, RESUMO, VER_PERFIL, POWER_BI, PROFISSIONAIS],
	[Role.tarm]: [
		OCORRENCIAS,
		NOVA_OCORRENCIA,
		CONSULTAS,
		UNIDADES,
		VER_PERFIL,
		HISTORICO_DE_PERCURSOS,
		RESUMO,
		POWER_BI,
		VISUALIZAR_CHAMADO,
	],
	[Role.medic]: [
		OCORRENCIAS,
		NOVA_OCORRENCIA,
		CONSULTAS,
		VER_PERFIL,
		UNIDADES,
		REGISTRO_DE_CENA,
		REGULACAO_MEDICA,
		RESUMO,
		POWER_BI,
	],
	[Role.radioOperator]: [
		RADIO_OPERADOR_INDEX,
		RADIO_OPERADOR_FORMULARIO,
		VIATURAS,
		NOVA_OCORRENCIA,
		CONSULTAS,
		UNIDADES,
		VER_PERFIL,
		RESUMO,
		PLANTAO,
		MAPA_DE_VIATURA,
		POWER_BI,
		HISTORICO_DE_PERCURSOS,
	],
	[Role.attendanceOrAmbulanceTeam]: [
		OCORRENCIAS,
		CONSULTAS,
		VER_PERFIL,
		REGISTRO_DE_CENA,
		RESUMO,
		POWER_BI,
	],
	[Role.fleetControl]: [VIATURAS, VER_PERFIL, MAPA_DE_VIATURA, HISTORICO_DE_PERCURSOS, POWER_BI],
	[Role.reportsConsulting]: [CONSULTAS, VER_PERFIL, RESUMO, BPA, POWER_BI],
	[Role.teamScale]: [VER_PERFIL, POWER_BI],
	[Role.hospital]: [VER_PERFIL, POWER_BI],
	[Role.support]: [VER_PERFIL, POWER_BI],
	[Role.monitor]: [VER_PERFIL, POWER_BI],
};
